import React, { useEffect } from "react";
import ResumeBuilderIcon from "../assets/icons/resume.png";
import OnlineEducationIcon from "../assets/icons/online-learning.png";
import OnlineLearning from "../assets/class.jpeg";
import Learning from "../assets/men.jpeg";
import Connection from "../assets/job.jpeg";
import Job from "../assets/icons/job-seeker.png";
import Consultant from "../assets/icons/consultant.png";
import { COLORS } from "../utils/Color";
import "./index.css";
import TitleBar from "../components/TitleBar";

const OfferCard = ({ title, des, icon }) => {
  return (
    <div
      style={{
        borderColor: COLORS.main,
        transition: "transform .2s",
      }}
      className="OfferCard zoom"
    >
      <img
        src={icon}
        alt="TimeNTech"
        style={{ height: "90px", width: "90px" }}
      />
      <div style={{ fontSize: "20px", fontWeight: "bold", color: COLORS.main }}>
        {title}
      </div>
      <div style={{ fontSize: "20px" }}>{des}</div>
    </div>
  );
};

function OurStrength() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    document.title = "TimeNTech | Our Strength";
  });
  return (
    <div>
      <TitleBar title="What We Offer" />
      <div
        className="offerContainer"
        style={{
          padding: "20px",
          display: "flex",
          margin: 10,
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <OfferCard
          title="Professional IT Courses"
          icon={OnlineEducationIcon}
          des={`Students with no prior IT experience can take advantage of our weekday and weekend 
          classes, which are interactive and rigorous. We provide interactive and demanding IT courses.`}
        />
        <OfferCard
          title="Resume Build Up"
          icon={ResumeBuilderIcon}
          des={`A concise and comprehensive resume helps you stand out from the crowd and attract employers' attention.`}
        />
        <OfferCard
          title="Job Assistance"
          icon={Job}
          des={`We offer job assistance to students at the conclusion of the course, including bootcamp, 
          interview preparation, an introduction to IT recruiters, and posting resumes on the job portal.`}
        />
        <OfferCard
          title="IT Consultancy"
          icon={Consultant}
          des={`TimeNTech can assist you in clearing up any doubts you may have about your QA career, 
          whether you are lost, in need of assistance, or want to talk about it.`}
        />
      </div>
      <div className="row">
        <div className="row-left" style={{ flexDirection: "column" }}>
          {/* <div style={{ color: COLORS.main, fontSize: 36 }}>8 Benefits of</div> */}
          <div style={{ color: COLORS.main, fontSize: 36, fontWeight: "bold" }}>
            <b>Digital & Virtual Classrooms</b>
          </div>
          <div
            style={{
              textAlign: "justify",
              marginTop: "2rem",
              fontSize: "20px",
            }}
          >
            The pandemic has shown us, among other things, that our world is more virtually 
            connected than ever before.A lot of work is now done entirely online.One of the 
            most significant shifts has occurred in the technology industry due to the 
            proliferation of remote-only positions.We have also implemented our online platform 
            to best prepare our students.In every class, we use the same technology and 
            communication tools that you would use at work.It is helpful to transition to a 
            virtually connected job with this familiarity.
          </div>
        </div>
        <div className="row-right">
          <img
            src={OnlineLearning}
            alt="TimeNTech"
            style={{
              height: "100%",
              width: "100%",
              boxShadow: "10px 10px 19px -1px rgba(48,100,161,0.75)",
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="row-left">
          <img
            src={Connection}
            alt="TimeNTech"
            style={{
              height: "100%",
              width: "100%",
              boxShadow: "10px 10px 19px -1px rgba(48,100,161,0.75)",
            }}
          />
        </div>
        <div className="row-right" style={{ flexDirection: "column" }}>
          <p style={{ color: COLORS.main, fontSize: 34, fontWeight: "bold" }}>
            Pre-requisites before landing a job
          </p>

          <div
            style={{
              textAlign: "justify",
              fontSize: "20px",
            }}
          >
            <p
              style={{
                margin: 0,
              }}
            >
              The following are examples of job readiness services that TimeNTech provides:
            </p>
            {/* <p style={{ margin: 0 }}>
              Interview techniques, such as pointers for expressing your
              experience, availability, and technical skills to employers
            </p>
            <p style={{ margin: 0 }}>
              Technical training on how to effectively draft:
            </p> */}
            <p
              style={{
                margin: 0,
                borderLeft: `3px solid ${COLORS.main}`,
                paddingLeft: "1rem",
                marginBottom: "1px",
              }}
            >
              Interview Techniques
            </p>
            <p
              style={{
                margin: 0,
                borderLeft: `3px solid ${COLORS.main}`,
                paddingLeft: "1rem",
                marginBottom: "1px",
              }}
            >
              Cover letter
            </p>
            <p
              style={{
                margin: 0,
                borderLeft: `3px solid ${COLORS.main}`,
                paddingLeft: "1rem",
                marginBottom: "1px",
              }}
            >
              Resume
            </p>
            <p
              style={{
                margin: 0,
                borderLeft: `3px solid ${COLORS.main}`,
                paddingLeft: "1rem",
                marginBottom: "1px",
              }}
            >
              References
            </p>
            <p
              style={{
                margin: 0,
                borderLeft: `3px solid ${COLORS.main}`,
                paddingLeft: "1rem",
                marginBottom: "1px",
              }}
            >
              Thank you letter
            </p>
          </div>
        </div>
      </div>
      <div className="strength-customer-container">
        <div className="row" style={{ marginBottom: "3rem" }}>
          <div className="row-left" style={{ flexDirection: "column" }}>
            <div
              style={{ color: COLORS.main, fontSize: 36, fontWeight: "bold" }}
            >
              <b>Career Changing Programs </b>
            </div>
            <div
              style={{
                textAlign: "justify",
                marginTop: "2rem",
                fontSize: "20px",
              }}
            >
              Students with a huge hope come to us from different background and  diversity. 
              They change their careers for flexibilty, reduce stress, salary increase, 
              opportunity for advancement, and feeling of appreciation. We guide and teach them 
              how to be a developed and skilled resource to the employer.
            </div>
          </div>
          <div className="row-right">
            <img
              src={Learning}
              alt="TimeNTech"
              style={{
                height: "100%",
                width: "100%",
                boxShadow: "10px 10px 19px -1px rgba(48,100,161,0.75)",
              }}
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="row-left">
            <Card
              title="QA Automation"
              icon={QAAutomation}
              des={`
              Learn core JavaScript programming. The program then teaches you how to use JavaScript in conjunction with leading test automation frameworks such as selenium to learn industry standards in web UI automation and testing.`}
            />
          </div>
          <div className="row-right">
            <Card
              title="Rest API Automation"
              icon={API}
              des={`Learn core JavaScript programming. Using JS we dive into testing APIs including how to automate various authentication methods. Learn the frameworks and tools demanded by the tech industry.`}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default OurStrength;
