import React, { useEffect } from "react";
import Banner from "../components/Banner";
import TitleBar from "../components/TitleBar";

function HomePage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "TimeNTech | Home";
  });

  return (
    <div>
      <Banner />
      <TitleBar title={"Who We Are"} />
      <div className="row" style={{ marginTop: "2rem" }}>
        <p style={{ textAlign: "justify" }}>
          We have a track record of providing high-quality, professional software 
          quality assurance courses that we can tailor to meet the requirements, 
          timeframes, and budgets of each individual customer.
        </p>
        <p style={{ textAlign: "justify" }}>
          We are adaptable and always use the most recent technology, which is in 
          high demand in the IT industry right now.By standardizing course materials, 
          reviewing them with industry professionals, and making them relevant to 
          upcoming technologies, we guarantee the quality of all of our training interventions.
        </p>
        <p style={{ textAlign: "justify" }}>
        Our instructors are professionals with years of experience and expertise in 
        their fields.Our ultimate objective is to provide candidates with excellent 
        training and prepare them for the IT job market in the future.Client care, 
        respect for their professional values, and necessities are our primary focuses.
        </p>
      </div>
    </div>
  );
}

export default HomePage;
