import React, { useEffect } from "react";
import TitleBar from "../components/TitleBar";
import { COLORS } from "../utils/Color";

function AboutPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "TimeNTech | About ";
  });

  return (
    <div>
      <TitleBar title="About Us" />
      <div
        className="row"
        style={{ display: "flex", alignItems: "flex-start" }}
      >
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontWeight: "bold", color: COLORS.main }}>
            TimeNTech
          </span>{" "}
          was founded in June 2022 with the inspiration to help the Bangladeshi
          and other diverse communities. As an immigrant from Bangladesh, we all
          come to the USA with great hope but not all of us got the proper
          guidance and opportunities to be successful.
        </p>
        <p style={{ textAlign: "justify" }}>
        In the US job market, skilled IT professionals are in high demand across 
        a variety of industries, including technology, health insurance, retail, 
        and more;Everywhere, skilled and educated workers are required.However, 
        a significant number of individuals from our communities are unable to take 
        advantage of these opportunities due to a lack of IT skills, so they end up 
        working odd jobs.
        </p>

        <p style={{ textAlign: "justify" }}>
        Our company's mission is to provide assistance to individuals interested 
        in IT careers.In the field of software quality assurance (QA), a bachelor's 
        degree in information technology or computer science is not required.However, 
        we do recommend having a bachelor's degree because it increases academic skill. 
        As long as they possess the appropriate set of skills to perform the job, 
        individuals from other fields can also envision a career in software testing.
        </p>
        <p style={{ textAlign: "justify" }}>
        Quality Assurance for Software Testing has turned into a fundamental piece 
        of the product life cycle.Software is being developed, improved, and upgraded 
        on a daily basis, and this process will continue into the future.Software 
        testing assists in identifying inconsistencies, gaps, or missing requirements.
        </p>
      </div>
    </div>
  );
}

export default AboutPage;
